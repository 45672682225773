export const emailValidator = (email: string): boolean => {
  console.log('email validator', email)
  if (email.length < 5) {
    console.log('email too short')
    return false
  }
  // taken from: https://www.w3resource.com/javascript/form/email-validation.php
  // checks: 'LL..LL@LL..LL.LLL.LLLL.LL
  if (/^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(email)) {
    console.log('email fine')
    return true
  } else {
    console.log('email error')
    return false
  }
}
