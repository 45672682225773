import React from 'react'
import {Global, css} from '@emotion/core'

import {FONTS} from '../../utils/constants'

const GlobalStyle = ({headerFont, contentFont, colorObject}) => {
  // console.log('GSheaderFont, GScontentFont:', FONTS[headerFont], FONTS[contentFont]);
  // const FONTS = [
  //   "Istok Web",
  //   "Nunito Sans",
  //   "Faustina",
  //   "Cantata One",
  //   "Trirong",
  //   "Ledger",
  //   "Oswald",
  //   "Staatliches",
  //   "'Libre Barcode 39 Extended Text', cursive",
  // ];
  return (
    <Global
      styles={css`
        body {
          --navColor: ${colorObject?.navColor};
          --imageColor: ${colorObject?.imageColor}; //uses #rrggbbaa notation
          --cardsColor: ${colorObject?.cardsColor};
          --boxesColor: ${colorObject?.boxesColor};
          --formColor: ${colorObject?.formColor};
          --footColor: ${colorObject?.footColor};
        }
        div,
        span,
        applet,
        object,
        iframe,
        p,
        blockquote,
        pre,
        a,
        abbr,
        acronym,
        address,
        big,
        cite,
        code,
        del,
        dfn,
        em,
        img,
        ins,
        kbd,
        q,
        s,
        samp,
        small,
        strike,
        strong,
        sub,
        sup,
        tt,
        var,
        b,
        u,
        i,
        center,
        dl,
        dt,
        dd,
        ol,
        ul,
        li,
        fieldset,
        form,
        label,
        legend,
        table,
        caption,
        tbody,
        tfoot,
        thead,
        tr,
        th,
        td,
        article,
        aside,
        canvas,
        details,
        embed,
        figure,
        figcaption,
        footer,
        header,
        hgroup,
        menu,
        nav,
        output,
        ruby,
        section,
        summary,
        time,
        mark,
        audio,
        video {
          font-family: ${FONTS[contentFont]};
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        h1 > p,
        .headerClass {
          font-family: ${FONTS[headerFont]};
        }
      `}
    />
  )
}

export default GlobalStyle
