export const cssVariables = {
  // hsl(0, 0%, 93%)
  // '--background-color': 'hsl(41, 16%, 93%)',
  // '--background-color': 'hsl(208, 79%, 28%)',
  // '--background-color': 'hsl(207, 34%, 37%)',
  '--background-color': 'hsl(207, 34%, 98%)',

  // '--paragraph-bg-color': 'hsl(41, 16%, 95%)',
  // '--paragraph-bg-color': 'hsl(208, 79%, 32%)',
  '--paragraph-bg-color': 'hsl(208, 79%, 97%)',
  // '--paragraph-bg-color': 'hsl(207, 34%, 45%)',

  // '--boxshadow-bg-color': 'hsl(41, 16%, 97%)',
  '--boxshadow-bg-color': 'hsl(208, 79%, 99%)',
  // '--boxshadow-bg-color': 'hsl(208, 79%, 34%)',
  // '--boxshadow-bg-color': 'hsl(207, 34%, 47%)',

  '--main-title': 'clamp(50px, 7vw, 90px)',
  '--nav-height': '5rem',
  '--muddybrown': '#3a3633', // muddy brown
  '--navyblue': 'hsl(207, 48%, 20%)', //blue navy rgba(30, 61, 87, 1) #1E3D57
  // '--nav-color': '#242426', //charcoal
  // '--nav-color': 'black',
  '--nav-color': 'whitesmoke',
  '--charcoal': '#242426',
  '--main-font': 'hsl(0, 100%, 0%)',
  '--navbar-width': '10rem',
  '--navbar-height': '4rem',

  '--secondary1': '#f9fcfb', // white slightly green
  '--secondary2': '#e4e1e7', // white lilac
  '--secondary3': '#c7a5bd', // pale fleshpink
  '--secondary4': '#c37aa2', // salmony pink
  '--secondary5': '#d76c79', // coral orange
  '--secondary6': '#f07e69', // sharp orange
  '--secondary7': '#ff9059', // danger orange
}

export const FONTS = [
  'Istok Web',
  'Nunito Sans',
  'Faustina',
  'Cantata One',
  'Trirong',
  'Ledger',
  'Oswald',
  'Staatliches',
  // "Libre Barcode 39 Extended Text, cursive",
]

export const MEDIA = {
  tablet: '700px',
  desktop: '900px',
}

// @media (min-width: ${MEDIA.tablet} ){

// };
// @media (min-width: ${MEDIA.desktop}){

// };

export const LOREM1 =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse semper accumsan quam, blandit sagittis nisl accumsan non. In ultricies libero vehicula lectus condimentum, vitae gravida risus ornare. Praesent nisl orci, eleifend ac erat id, vulputate varius mi. Phasellus et risus quis metus rutrum dictum. Quisque ac tortor commodo, malesuada nisi sit amet, viverra erat. Maecenas et purus sit amet justo viverra maximus. Nullam hendrerit et diam sit amet venenatis. Fusce finibus hendrerit diam. Sed nec nisl nulla.'

export const LOREM2 =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse semper accumsan quam, blandit sagittis nisl accumsan non. In ultricies libero vehicula lectus condimentum, vitae gravida risus ornare. Praesent nisl orci, eleifend ac erat id, vulputate varius mi. Phasellus et risus quis metus rutrum dictum. Quisque ac tortor commodo, malesuada nisi sit amet, viverra erat. Maecenas et purus sit amet justo viverra maximus. Nullam hendrerit et diam sit amet venenatis. Fusce finibus hendrerit diam. Sed nec nisl nulla. /n Phasellus semper nec eros et rutrum. Donec non urna vel massa consequat porta. Vivamus et cursus sapien. Ut tincidunt nibh eget magna tempor facilisis. Maecenas placerat metus eget tincidunt efficitur. Nunc rutrum quam ac euismod sollicitudin. Ut lobortis tincidunt tellus nec rutrum. Curabitur accumsan libero quam, id scelerisque ipsum mattis eget. Praesent at justo lorem. Morbi sit amet pharetra ante, malesuada efficitur ante. Proin non dignissim arcu, sit amet aliquam quam. Nunc sit amet accumsan sapien.'
